import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  AddInstruction,
  Communitylisting,
  DeleteCommunity,
  DowanloadCommunitylist,
  editInstruction,
  getInstruction,
} from "../Redux/Actions/AdminActions";
import Commondashboard from "../Common/Commondashboard";
import PaginationComponet from "../Components/Pagination";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import * as XLSX from "xlsx";
import SearchBar from "../Common/SearchBar";
import Commonheader from "../Common/Commonheader";
import moment from "moment";
import RTextAditor from "../Components/TextEditor";
import draftToHtml from "draftjs-to-html";

export default function Community() {
  const Communityposts = useSelector((state) => state.community?.alldata);
  const { instructionData } = useSelector((state) => state.instruction);
  console.log(Communityposts);
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [page, setPage] = useState(0);
  const [flag, setflag] = useState(true);
  const [ID, setId] = useState("");
  const [Postid, setpostid] = useState("");
  const [showd, setshowd] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [htmlEntity, setHtmlEntity] = useState("");
  const [htmlContenterr, setHtmlContenterr] = useState();
  const [htmlContent, setHtmlContent] = useState();
  const [webdata, setwebdata] = useState();
  const [givenContent, setGivenContent] = useState();

  const dataHtmlHandle = (html) => {
    setHtmlEntity(html);
    setHtmlContent(draftToHtml(JSON.parse(html)));
    setHtmlContenterr("");
  };

  console.log(htmlContent);
  console.log(htmlEntity);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    dispatch(
      Communitylisting({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const payload = {
      page: page,
      limit: limit,
      search: search,
      // search: searchValue,
    };
    dispatch(Communitylisting(payload));
  }, [limit, search, page, searchValue, flag]);

  const handledeleteCommunity = () => {
    dispatch(DeleteCommunity({ id: Postid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadCommunitylist());
      console.log(response);

      const allData = response?.payload?.data?.data;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const handlenavigate = (id) => {
    navigate(`/edit-post/${id}`);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    if (instructionData?.data?.description) {
      // setInstruction(instructionData?.data?.description);
      setHtmlContent(instructionData?.data?.description);
      setGivenContent(instructionData?.data?.description);
      setwebdata();
    }
  }, [instructionData]);

  const handleSave = () => {
    if (instructionData?.data?._id) {
      dispatch(editInstruction({ description: htmlContent }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            toast.success(res?.payload?.data?.message);
            setIsModalOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      dispatch(AddInstruction({ description: htmlContent }))
        .then((res) => {
          console.log(res);
          if (res?.payload?.data?.status === 200) {
            toast.success(res?.payload?.data?.message);
            setIsModalOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    dispatch(getInstruction());
  }, [isModalOpen]);

  return (
    <Layout>
      <Container fluid>
        <Commonheader />
      </Container>

      <Commondashboard />

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="user-search">
              <div className="drop-down ad-btn-page">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
                <div className="">
                  <Link to="/create-post">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99984 3.33301V12.6663"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.3335 8.00033H12.6668"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Create
                  </Link>
                </div>
                <div className="" onClick={showModal}>
                  <Link>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99984 3.33301V12.6663"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.3335 8.00033H12.6668"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {instructionData?.data?._id ? `Edit` : `Add`} Instructions
                  </Link>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <SearchBar setSearchValue={setSearchValue} />
                    </Form.Group>
                  </Form>
                </div>
                <div className="export-box" onClick={fetchDataAndExportToExcel}>
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">S.No</div>
                    </th>
                    <th>Created on</th>
                    <th>Description</th>
                    <th>Created by </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Communityposts?.data?.map((res, index) => {
                     const dynamicIndex = page * limit + (index + 1);


                    return (
                      <tr key={index}>
                        <td>
                          <div className="first-user" >
                            <div className="debit">{dynamicIndex}</div>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>
                              {" "}
                              {moment(res?.createdAt).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="cont-num">
                            {res?.description || "N/A"}
                          </div>
                        </td>
                        <td>
                          <div className="debit">
                            {res?.user_id?.name || "N/A"}
                          </div>
                        </td>

                        <td>
                          <div className="action user-verification">
                            <div onClick={() => handlenavigate(res?._id)}>
                              <img
                                style={{ cursor: "pointer" }}
                                src={
                                  require("../Assets/Images/edit_icon.svg")
                                    .default
                                }
                              />
                            </div>

                            <img
                              src={
                                require("../Assets/Images/white_delete_icon.svg")
                                  .default
                              }
                              onClick={() => {
                                setshowd(true);
                                setpostid(res?._id);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {page * limit + 1} -{" "}
                    {Math.min((page + 1) * limit, Communityposts?.count)} of{" "}
                    {Communityposts?.count} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={page}
                    totalCount={Communityposts?.count}
                    limit={Math.ceil(Communityposts?.count / limit)}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to Remove this Community Post?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handledeleteCommunity}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for adding instructions */}
      <Modal show={isModalOpen} onHide={handleCancel} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {instructionData?.data?._id ? `Edit` : `Add`} Instruction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RTextAditor
            htmlHandle={dataHtmlHandle}
            givenContent={givenContent}
            webData={webdata}
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="theme-btn" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
