import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getAdminprofile } from '../Redux/Actions/AdminActions';
import { Dropdown } from 'react-bootstrap';
import { CommonLogout } from './CommonLogout';
import Logo from "../Assets/Images/Placeholder-(1)-1726559462042.png"
import { useNavigate } from 'react-router-dom';


function Commonheader() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [admindetails, setAdmindetails] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
   

    useEffect(() => {
        dispatch(getAdminprofile())
          .then((response) => {
            console.log(response, "admin data");
            if (response?.payload?.status === 200) {
              setAdmindetails(response?.payload?.data);
            }
          })
          .catch((error) => {
            console.log(error, "Error occur");
          });
      }, []);

      const NavigatetoProfile = () => {
        navigate('/my-profile')
      }

  return (
    <div className="dashboard-items mt-3">
    <div className="right-top-bar">
      <div className="left-side-bar">
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M19 19L13 13"
            stroke="#2F2B3D"
            stroke-opacity="0.9"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle
            cx="8"
            cy="8"
            r="7"
            stroke="#2F2B3D"
            stroke-opacity="0.9"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <input type="tetx" placeholder="Search" /> */}
      </div>
      <div className="right-side-bar">
        <div className="notify-icon">
          <div className="notification-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C16.3402 6.10655 17.8786 8.41425 18 11V14C18.1526 15.2608 18.8949 16.3742 20 17H4C5.10511 16.3742 5.84739 15.2608 6 14V11C6.12137 8.41425 7.65983 6.10655 10 5"
                stroke="#2F2B3D"
                stroke-opacity="0.9"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 17V18C9 19.6569 10.3431 21 12 21C13.6569 21 15 19.6569 15 18V17"
                stroke="#2F2B3D"
                stroke-opacity="0.9"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
        
          </div>
        </div>
        <div className="user-img-top">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">

              {admindetails && admindetails?.profile_pic ? (
                <img src={`${url}public/${admindetails?.profile_pic}`} alt="Profile" />
              ) : (
                <img src={Logo} />
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item className="">
                <div className="drop-pro-view">
            
                   {admindetails && admindetails?.profile_pic ? (
                <img src={`${url}public/${admindetails?.profile_pic}`} alt="Profile" />
              ) : (
                <img src={Logo} />
              )}
                  <div className="admin-name">
                    <h3>{admindetails?.name}</h3>
                    <p>Admin</p>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              <div className="my-profile">
                <Dropdown.Item  onClick={NavigatetoProfile}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <ellipse
                      cx="10.9999"
                      cy="6.41667"
                      rx="3.66667"
                      ry="3.66667"
                      stroke="#2F2B3D"
                      stroke-opacity="0.9"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                      stroke="#2F2B3D"
                      stroke-opacity="0.9"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>{" "}
                  My Profile
                </Dropdown.Item>
              </div>

           
              <Dropdown.Divider />
              
             
              <CommonLogout/>



            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Commonheader