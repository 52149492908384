import React from "react";
import Pagination from "react-bootstrap/Pagination";

const PaginationComponet = ({
  currentPage,
  totalCount,
  limit,
  onPageChange,
}) => {

  console.log(currentPage, "sdf324234234", limit);
  const pageItems = [];
  for (let number = 0; number < limit; number++) {
    pageItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => onPageChange(number)} // Pass zero-based page number
      >
        {number + 1} {/* Display 1-based page number */}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      <Pagination.First
        onClick={() => onPageChange(0)} // Navigate to the first page (0)
        disabled={currentPage === 0}
      />
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 0} // Disable if on first page (0)
      />
      {pageItems}
      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === limit - 1} // Disable if on last page
      />
      <Pagination.Last
        onClick={() => onPageChange(currentPage[pageItems.length -1] )} // Navigate to the last page (totalPages - 1)
        disabled={currentPage === limit - 1}
      />
    </Pagination>
  );
};

export default PaginationComponet;
