import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Countrycode from "../Countrycode.json";
import * as Yup from "yup";
import Logo from "../Assets/Images/Placeholder-(1)-1726559462042.png";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Addnotification,
  Editadminprofile,
  chnagePassword,
  getAdminprofile,
} from "../Redux/Actions/AdminActions";
import { Button } from "react-bootstrap";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Please select a user type"),
  title: Yup.string().trim().required("Required"),
  desc: Yup.string().trim().required("Required"),
});



export default function CreateNotification() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const InitialValues = {
    type: "",
    title: "",
    desc: "",
  };

  const handleSubmit = async (values) => {
    const payload = {
      title: values?.title,
      sent_to: values?.type,
      description: values?.desc,
    };
    try {
      const data = await dispatch(Addnotification(payload));
      console.log(data);
      if (data?.payload?.data?.status === 200) {
        toast.success(data?.payload?.data?.message);
        navigate("/notification");
      } else {
        toast.error(data?.payload?.data?.message);
      }
    } catch (error) {
      console.log(error, "error occur");
    }
  };


  
 

  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <div className="right-top-bar">
            <div className="inner-page-lable">
              <Link to="/notification">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M3 8.00008L1.93934 9.06074L0.87868 8.00008L1.93934 6.93942L3 8.00008ZM23 6.50008C23.8284 6.50008 24.5 7.17165 24.5 8.00008C24.5 8.82851 23.8284 9.50008 23 9.50008V6.50008ZM8.60601 15.7274L1.93934 9.06074L4.06066 6.93942L10.7273 13.6061L8.60601 15.7274ZM1.93934 6.93942L8.60601 0.272755L10.7273 2.39408L4.06066 9.06074L1.93934 6.93942ZM3 6.50008L23 6.50008V9.50008L3 9.50008L3 6.50008Z"
                    fill="#071523"
                  />
                </svg>
                Create Notification
              </Link>
            </div>
          </div>
        </div>
      </Container>

      <Container fluid>
        <div className="my-profile-page mt-4">
          <Formik
            initialValues={InitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleSubmit,
             
            }) => (
              <Form onSubmit={handleSubmit}>
               
                <div className="profile-top-box">
                  <div className="profile-disp mt-5">
                    <div className="form-set input-profile">
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Select Users</Form.Label>
                        <Form.Select
                        aria-label="Default select example"
                        name="type"
                        value={values?.type}
                        onChange={handleChange}
                      >
                        <option> Select </option>
                        <option value="1">All Customers</option>
                        <option value="2">All Trainers</option>
                        <option value="3">All Users</option>
                      </Form.Select>
                      <ErrorMessage
                        name="type"
                        component="div"
                        className="error-message"
                      />
                       
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="type"
                          placeholder="Type Here"
                          name="title"
                          value={values?.title}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="error-message"
                        />
                        
                      </Form.Group>
                    </div>

                    <div className="form-set input-profile mt-2">
                      <Form.Group
                        className="mb-3 w-100"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Type Here"
                          as="textarea"
                          row={4}
                          name="desc"
                          value={values?.desc}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="desc"
                          component="div"
                          className="error-message"
                        />
                      
                      </Form.Group>
                    </div>
                  </div>

                  <div className="btn-up mt-2">
                    <button type="submit">Create</button>
                    <div className="reset-box">
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/user");
                        }}
                      >
                        Cancel
                      </p>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>
    </Layout>
  );
}
