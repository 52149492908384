import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal, Button } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  FAQList,
  deletefaq,
  dowanloadfaq,
  usermanagement,
} from "../Redux/Actions/AdminActions";
import PaginationComponet from "../Components/Pagination";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import Commondashboard from "../Common/Commondashboard";
import Commonheader from "../Common/Commonheader";

export default function FaqManagement() {
  const faqdata = useSelector((state) => state?.faqmgmt?.faqalldata);
  console.log(faqdata);

  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [faqid, setfaqId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
    };
    dispatch(usermanagement(searchData));
  }, [page, limit, flag, dispatch]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      FAQList({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };
    dispatch(FAQList(searchData));
  }, [page, limit, search, flag]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(dowanloadfaq());
      console.log(response);

      const allData = response?.payload?.data?.data;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const handlenavigate = (id) => {
    navigate(`/edit-faqs/${id}`);
  };

  const handlerdeletefaq = () => {
    dispatch(deletefaq({ id: faqid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  return (
    <Layout>
      <Container fluid>
        <Commonheader />
      </Container>

      <Commondashboard />

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="user-search">
              <div className="drop-down ad-btn-page">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
                <div className="">
                  <Link to="/add-faqs">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99984 3.33301V12.6663"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.3335 8.00033H12.6668"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Create
                  </Link>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search User"
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
                <div className="export-box" onClick={fetchDataAndExportToExcel}>
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">S.No</div>
                    </th>

                    <th>Question </th>
                    <th>Answer</th>

                    {/* <th>Status</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {faqdata?.data?.map((res, index) => {
                    const dynamicIndex = page * limit + (index + 1);

                    return (
                      <tr key={index}>
                        <td>
                          <div className="first-user">
                            <div className="debit">{dynamicIndex}</div>
                          </div>
                        </td>

                        <td>
                          <div className="debit">{res?.question || "N/A"}</div>
                        </td>
                        <td>
                          <div className="debit">{res?.answer || "N/A"}</div>
                        </td>

                        <td>
                          <div className="action user-verification">
                            <div onClick={() => handlenavigate(res?._id)}>
                              <img
                                src={
                                  require("../Assets/Images/edit_icon.svg")
                                    .default
                                }
                              />
                            </div>

                            <img
                              src={
                                require("../Assets/Images/white_delete_icon.svg")
                                  .default
                              }
                              onClick={() => {
                                setshowd(true);
                                setfaqId(res?._id);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {currentPage * limit + 1} -{" "}
                    {Math.min((currentPage + 1) * limit, faqdata?.count)} of{" "}
                    {faqdata?.count} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={currentPage}
                    totalCount={faqdata?.count}
                    limit={Math.ceil(faqdata?.count / limit)}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to Remove this Account?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handlerdeletefaq}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
