import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Sidebar({ isActive, setIsActive, setShow, show }) {
  return (
    <aside>
      <div className="sidebar">
        <div className="logo-side">
          <Link to="/user">
            <img
              className="big"
              src={require("../../Assets/Images/side-bar-logo.svg").default}
            />
          </Link>
        </div>
        <div className="side-menu">
          <div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/under-maintenance"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/under-maintenance"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2.5"
                    y="10"
                    width="5"
                    height="6.66667"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="7.5"
                    y="6.66675"
                    width="5"
                    height="10"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="12.5"
                    y="3.33325"
                    width="5"
                    height="13.3333"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.3335 16.6667H15.0002"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> Analytics</p>
              </Link>
            </div>

            <div className="side-pages">
              <p>PAGES</p>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/user"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/user"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="11.0002"
                    cy="6.41667"
                    rx="3.66667"
                    ry="3.66667"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> User Management</p>
              </Link>
            </div>

            {/* <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/user-verification"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/user-verification"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="8.25016"
                    cy="6.41667"
                    rx="3.66667"
                    ry="3.66667"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.75 19.25V17.4167C2.75 15.3916 4.39162 13.75 6.41667 13.75H10.0833C12.1084 13.75 13.75 15.3916 13.75 17.4167V19.25"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.6665 10.0833L16.4998 11.9167L20.1665 8.25"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>User Verification</p>
              </Link>
            </div> */}

            

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/community"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/community"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.1665 4.58333C9.1665 3.57081 9.98732 2.75 10.9998 2.75C12.0124 2.75 12.8332 3.57081 12.8332 4.58333C14.9783 5.59767 16.3886 7.71306 16.4998 10.0833V12.8333C16.6397 13.9891 17.3201 15.0097 18.3332 15.5833H3.6665C4.67953 15.0097 5.35994 13.9891 5.49984 12.8333V10.0833C5.61109 7.71306 7.02135 5.59767 9.1665 4.58333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.25 15.5835V16.5002C8.25 18.0189 9.48122 19.2502 11 19.2502C12.5188 19.2502 13.75 18.0189 13.75 16.5002V15.5835"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Community</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/notification"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/notification"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.1665 4.58333C9.1665 3.57081 9.98732 2.75 10.9998 2.75C12.0124 2.75 12.8332 3.57081 12.8332 4.58333C14.9783 5.59767 16.3886 7.71306 16.4998 10.0833V12.8333C16.6397 13.9891 17.3201 15.0097 18.3332 15.5833H3.6665C4.67953 15.0097 5.35994 13.9891 5.49984 12.8333V10.0833C5.61109 7.71306 7.02135 5.59767 9.1665 4.58333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.25 15.5835V16.5002C8.25 18.0189 9.48122 19.2502 11 19.2502C12.5188 19.2502 13.75 18.0189 13.75 16.5002V15.5835"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Notifications</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/faqs"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/faqs"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.1665 4.58333C9.1665 3.57081 9.98732 2.75 10.9998 2.75C12.0124 2.75 12.8332 3.57081 12.8332 4.58333C14.9783 5.59767 16.3886 7.71306 16.4998 10.0833V12.8333C16.6397 13.9891 17.3201 15.0097 18.3332 15.5833H3.6665C4.67953 15.0097 5.35994 13.9891 5.49984 12.8333V10.0833C5.61109 7.71306 7.02135 5.59767 9.1665 4.58333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.25 15.5835V16.5002C8.25 18.0189 9.48122 19.2502 11 19.2502C12.5188 19.2502 13.75 18.0189 13.75 16.5002V15.5835"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>FAQs</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/support"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/support"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="3.6665"
                    y="11.9165"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="14.6665"
                    y="11.9165"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.6665 13.7498V10.9998C3.6665 6.94975 6.94975 3.6665 10.9998 3.6665C15.0499 3.6665 18.3332 6.94975 18.3332 10.9998V13.7498"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 17.4165C16.5 18.9353 14.0376 20.1665 11 20.1665"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Support</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
