import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Countrycode from "../Countrycode.json";
import * as yup from "yup";
import Logo from "../Assets/Images/Placeholder-(1)-1726559462042.png";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { addfaq, editFaq, getfaqdetails } from "../Redux/Actions/AdminActions";

const ValidationSchema = yup.object().shape({
  question: yup.string().required("Required"),
  answer: yup.string().required("Required"),
});

export default function EditFaq() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Faqdetails, setfaqdetails] = useState("");
  
  const { id } = useParams();

  const initialValues = {
    question: "",
    answer: "",
  };

  const handleAddFAQ = (values ) => {
   

    dispatch(editFaq({id:id, question: values?.question, answer: values?.answer}))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          navigate("/faqs");
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "err occur");
      });
  };

  useEffect(() => {
    dispatch(getfaqdetails({faqid:id}))
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.data?.status === 200) {
          setfaqdetails(response?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <div className="right-top-bar">
            <div className="inner-page-lable">
              <Link to="/faqs">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M3 8.00008L1.93934 9.06074L0.87868 8.00008L1.93934 6.93942L3 8.00008ZM23 6.50008C23.8284 6.50008 24.5 7.17165 24.5 8.00008C24.5 8.82851 23.8284 9.50008 23 9.50008V6.50008ZM8.60601 15.7274L1.93934 9.06074L4.06066 6.93942L10.7273 13.6061L8.60601 15.7274ZM1.93934 6.93942L8.60601 0.272755L10.7273 2.39408L4.06066 9.06074L1.93934 6.93942ZM3 6.50008L23 6.50008V9.50008L3 9.50008L3 6.50008Z"
                    fill="#071523"
                  />
                </svg>
                Edit FAQ
              </Link>
            </div>
          </div>
        </div>
      </Container>

      <Container fluid>
        <div className="my-profile-page mt-4">
          <Formik
            enableReinitialize={true}
            initialValues={Faqdetails || initialValues}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {
              handleAddFAQ(values);
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form>
                <div className="profile-top-box">
                  <div className="profile-disp mt-5">
                    <div className="form-set input-profile">
                      <Form.Group
                        className="mb-3 w-100"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Question</Form.Label>
                        <Form.Control
                          type="type"
                          placeholder="Enter question..."
                          name="question"
                          value={values?.question}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="question"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>
                    </div>

                    <div className="form-set input-profile mt-2">
                      <Form.Group
                        className="mb-3 w-100"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Answer</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter answer here..."
                          as="textarea"
                          row="12"
                          name="answer"
                          value={values?.answer}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="answer"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="btn-up mt-2">
                    <button type="button" onClick={handleSubmit}>
                      Update
                    </button>

                    <div className="reset-box">
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/faqs");
                        }}
                      >
                        Cancel
                      </p>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>
    </Layout>
  );
}
