import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Countrycode from "../Countrycode.json";
import * as yup from "yup";
import Logo from "../Assets/Images/Placeholder-(1)-1726559462042.png";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import pdflogo from "../../src/Assets/Images/pdf_icon.png";
import ModalImage from "react-modal-image";
import {
  DeleteUserAccount,
  getuserprofile,
  suspenduser,
} from "../Redux/Actions/AdminActions";

export default function TrainerDetails() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [trainerdetails, setTrainerdetails] = useState("");
  const [ActivityDetails, setActivityDetails] = useState([]);
  const [showd, setshowd] = useState(false);
  const [show, setshow] = useState(false);
  const [flag, setflag] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getuserprofile({ id: id }))
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.data?.status === 200) {
          setTrainerdetails(response?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  const handleDeleteTrainer = () => {
    dispatch(DeleteUserAccount({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
          navigate("/user");
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  const handleSuspend = async () => {
    const data = await dispatch(suspenduser({ id: id, status: 2 }));
    console.log(data);
    if (data?.payload?.data?.status === 200) {
      toast?.success(data?.payload?.data?.message);
      setshow(false);
      navigate("/user");
    }
  };

  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <div className="right-top-bar">
            <div className="inner-page-lable">
              <Link to="/user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M3 8.00008L1.93934 9.06074L0.87868 8.00008L1.93934 6.93942L3 8.00008ZM23 6.50008C23.8284 6.50008 24.5 7.17165 24.5 8.00008C24.5 8.82851 23.8284 9.50008 23 9.50008V6.50008ZM8.60601 15.7274L1.93934 9.06074L4.06066 6.93942L10.7273 13.6061L8.60601 15.7274ZM1.93934 6.93942L8.60601 0.272755L10.7273 2.39408L4.06066 9.06074L1.93934 6.93942ZM3 6.50008L23 6.50008V9.50008L3 9.50008L3 6.50008Z"
                    fill="#071523"
                  />
                </svg>
                Trainer Profile
              </Link>
            </div>
          </div>
        </div>
      </Container>

      <Container fluid>
        <div className="my-profile-page mt-4">
          <Form>
            <div className="profile-top-box">
              <div className="photo-upload">
                <div className="profile-picture">
                  <img
                    src={
                      trainerdetails?.profile_pic
                        ? `${url}public/${trainerdetails?.profile_pic}`
                        : Logo
                    }
                    //  alt="Profilesss"
                  />
                </div>
              </div>

              <div className="profile-disp mt-5">
                <div className="form-set input-profile">
                  <Form.Group
                    className="mb-3 input-field-width"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="type"
                      placeholder="First Name"
                      value={trainerdetails?.name || "N/A"}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3 input-field-width"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={trainerdetails?.email || "N/A"}
                      readOnly
                    />
                  </Form.Group>
                </div>

                <div className="form-set input-profile mt-2">
                  <Form.Group
                    className="mb-3 input-field-width"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Bio</Form.Label>
                    <textarea
                      class="form-control"
                      placeholder="Leave a comment here"
                      value={trainerdetails?.bio || "N/A"}
                      readOnly
                    ></textarea>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 input-field-width"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Address </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Contact"
                      value={trainerdetails?.address || "N/A"}
                      readOnly
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </Form>

          <div className="profile-top-box mt-4">
            <div className="experience">
              <h2> Experience </h2>
              <div className="qualifications">
                {trainerdetails?.training_orientation &&
                trainerdetails.training_orientation.length > 0 ? (
                  trainerdetails.training_orientation.map((qual) => (
                    <div className="name-of-quel" key={qual._id}>
                      <p>{qual.name}</p>
                    </div>
                  ))
                ) : (
                  <p>No qualifications available</p>
                )}
              </div>
            </div>
          </div>
          <div className="profile-top-box mt-4">
            <div className="experience">
              <h2>Preferred Location</h2>
              <div className="qualifications">
                {trainerdetails?.address_preferences &&
                trainerdetails.address_preferences.length > 0 ? (
                  trainerdetails.address_preferences.map((loc, index) => (
                    <div className="name-of-quel" key={index}>
                      <p>{loc === 1 ? "UAE" : loc === 2 ? "Dubai" : "Sharjah" }</p>
                    </div>
                  ))
                ) : (
                  <p>Location not found</p>
                )}
              </div>
            </div>
          </div>

          <div className="profile-top-box mt-4">
            <div className="all-links">
              <div className="experience socal-media-links">
                <h2> SOCIAL MEDIA</h2>
                <div className="media-links">
                  <p>Facebook</p>
                  <span>{trainerdetails?.fb_url || "N/A"}</span>
                </div>
                <div className="media-links">
                  <p>Instagram</p>
                  <span>{trainerdetails?.instagram_url || "N/A"}</span>
                </div>
                <div className="media-links">
                  <p>Snapchat</p>
                  <span>{trainerdetails?.snapchat_url || "N/A"}</span>
                </div>
              </div>
              <div className="where-based socal-media-links">
                <div className="experience">
                  <h2> Where are you based?</h2>
                  <div className="name-of-quel">
                    <p>
                      {trainerdetails?.based_on &&
                      trainerdetails.based_on.length > 0
                        ? trainerdetails.based_on
                            .map((res) =>
                              res === 1
                                ? "Home"
                                : res === 2
                                ? "Gym"
                                : "Outdoors"
                            )
                            .join(", ")
                        : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="profile-top-box mt-4">
            <div className="images-and-videos">
              <div className="up-images">
                <h2>Pictures</h2>

                {trainerdetails?.images && trainerdetails.images.length > 0 ? (
                  trainerdetails.images.map((res, index) => (
                    <div key={index} className="grid-images mb-3 col-6">
                      <ModalImage
                        small={`${url}public/${res}`}
                        medium={`${url}public/${res}`}
                        alt={`Photo ${index}`}
                        className="profile-add-img"
                      />
                    </div>
                  ))
                ) : (
                  <p>No Picture Found</p>
                )}

                <div className="banner-image mt-4">
                  <h2> Banner Image</h2>
                  <div className="banner-images">
                    {trainerdetails?.banner_img ? (
                      <img src={`${url}public/${trainerdetails?.banner_img}`} />
                    ) : (
                      <p>images not uploaded yet</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="videos-section">
                <h2>Video</h2>
                <div className="video-section">
                  <video
                    src={`${url}public/${trainerdetails?.video_presentation}`}
                    controls
                  />
                </div>
              </div>
            </div>
          </div>

          <Row>
            <Col lg={12}>
              <div className="profile-top-box mt-4">
                <div className="heading-profile">
                  <h2>Document</h2>
                  {/* <div className="upld-photo-lable ">
                    <div className="iupld-photo-item">
                      
                      {trainerdetails?.govt_id ? (
                        trainerdetails.govt_id.endsWith(".pdf") ? (
                          <a
                            href={`${url}public/${trainerdetails.govt_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Document
                          </a>
                        ) : (
                          <img
                            src={`${url}public/${trainerdetails.govt_id}`}
                            alt="Profile"
                          />
                        )
                      ) : (
                        <p>No document found</p>
                      )}
                    </div>
                  </div> */}

                  <div className="upld-photo-label">
                    <div className="iupld-photo-item">
                      {trainerdetails?.govt_id ? (
                        trainerdetails.govt_id.endsWith(".pdf") ? (
                          <div className="cv-upld-view">
                            <a
                              className="profile-add-img"
                              href={`${url}public/${trainerdetails.govt_id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={require("../Assets/Images/pdf_icon.png")}
                                alt="Open Document"
                                style={{ cursor: "pointer" }}
                              />
                              <p>View Document</p>
                            </a>
                          </div>
                        ) : (
                          <div className="cv-upld-view">
                            <img
                              className="profile-add-img"
                              src={`${url}public/${trainerdetails.govt_id}`}
                              alt="Profile"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                window.open(
                                  `${url}public/${trainerdetails.govt_id}`,
                                  "_blank"
                                )
                              }
                            />
                            <p>View Image</p>
                          </div>
                        )
                      ) : (
                        <p>No document found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Form>
                <div className="profile-top-box mt-4">
                  <div className="heading-profile">
                    <h2>Activity</h2>
                  </div>
                  <div className="activity-list-user">
                    {ActivityDetails?.map((details) => {
                      return (
                        <ul>
                          <li>
                            <h6>{details?.title}</h6>
                            <p>
                              {ActivityDetails && ActivityDetails.length > 0 ? (
                                <ul>
                                  {ActivityDetails.map((details, index) => (
                                    <li key={index}>
                                      <h6>{details?.title}</h6>
                                      <p>
                                        {details?.createdAt
                                          ? new Date(details?.createdAt)
                                              .toISOString()
                                              .slice(0, 10)
                                              .replace(/-/g, "/")
                                          : "N/A"}
                                      </p>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p>No activities happened yet</p>
                              )}
                            </p>
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                </div>
              </Form>
            </Col>

            <Col lg={6}>
              <Row>
                <Col lg={12}>
                  <div className="profile-top-box mt-4">
                    <div className="heading-profile">
                      <h2>Delete Account</h2>
                      <p>
                        Once you delete your account, you can not retrieve the
                        account. Please be certain.
                      </p>
                      <div className="btn-up mt-2">
                        <button type="button" onClick={() => setshowd(true)}>
                          Delete Account
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="profile-top-box mt-4">
                    <div className="heading-profile">
                      <h2>Suspend Account</h2>
                      <p>
                        You will not be able to receive messages, notifications
                        for up to 24hours.
                      </p>
                      <div className="btn-up mt-2">
                        <button type="button" onClick={() => setshow(true)}>
                          Suspend Account
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeleteTrainer}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Suspend this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleSuspend}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
