import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteNotification,
  DowanloadNotificationFile,
  getnotificationlist,
} from "../Redux/Actions/AdminActions";
import { CommonLogout } from "../Common/CommonLogout";
import Commondashboard from "../Common/Commondashboard";
import PaginationComponet from "../Components/Pagination";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import * as XLSX from "xlsx";
import SearchBar from "../Common/SearchBar";
import Commonheader from "../Common/Commonheader";

export default function Notification() {
  const notificationData = useSelector((state) => state.notimgmt?.AllNoti);
  console.log(notificationData);
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [page, setPage] = useState(0);
  const [flag, setflag] = useState(true);
  const [ID, setId] = useState("");
  const [showd, setshowd] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    dispatch(
      getnotificationlist({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const payload = {
      page: page,
      limit: limit,
      search: search,
      search: searchValue,
    };
    dispatch(getnotificationlist(payload));
  }, [limit, search, page, searchValue, flag]);

  const handledeletenotification = () => {
    dispatch(DeleteNotification({ id: ID }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadNotificationFile());
      console.log(response);

      const allData = response?.payload?.data?.data;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  return (
    <Layout>
      <Container fluid>
       <Commonheader/>
      </Container>

      <Commondashboard />

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="user-search">
              <div className="drop-down ad-btn-page">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
                <div className="">
                  <Link to="/create-notification">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99984 3.33301V12.6663"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.3335 8.00033H12.6668"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Create
                  </Link>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <SearchBar setSearchValue={setSearchValue} />
                    </Form.Group>
                  </Form>
                </div>
                <div className="export-box" onClick={fetchDataAndExportToExcel}>
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">S.No</div>
                    </th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Sent to </th>
                    {/* <th>Status</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {notificationData?.data?.map((res, index) => {
                      const dynamicIndex = page * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>
                          <div className="first-user">
                            <div className="debit">{dynamicIndex}</div>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>{res?.title || "N/A"}</p>
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="cont-num">
                            {res?.description || "N/A"}
                          </div>
                        </td>
                        <td>
                          <div className="debit">
                            {res?.sent_to === 1
                              ? "All Customers"
                              : res?.sent_to === 2
                              ? "All Trainers"
                              : "All Users"}
                          </div>
                        </td>

                        <td>
                          <div className="notification-delete-icon">
                            <img
                              src={
                                require("../Assets/Images/white_delete_icon.svg")
                                  .default
                              }
                              onClick={() => {
                                setshowd(true);
                                setId(res?._id);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {page  * limit + 1} -{" "}  
                    {Math.min((page + 1) * limit, notificationData?.count)} of{" "}
                    {notificationData?.count} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={page}
                    totalCount={notificationData?.count}
                    limit={Math.ceil(notificationData?.count / limit)}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to Remove this Notification?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handledeletenotification}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
