import { createSlice } from "@reduxjs/toolkit";
import { Communitylisting } from "../Actions/AdminActions";



export const communitySlice = createSlice({
    name: "communitySlice",
    initialState: {
        alldata: [],
        loading: false,
        error: null,
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(Communitylisting.pending, (state) => {
                state.loading = true; 
                state.error = null;   
            })
            .addCase(Communitylisting.fulfilled, (state, action) => {
                state.loading = false; 
                state.alldata = action?.payload?.data;
            })
            .addCase(Communitylisting.rejected, (state, action) => {
                state.loading = false; 
                state.error = action.error.message; 
            });
    },
});

export default communitySlice.reducer;
