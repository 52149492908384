import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import UnderMaintenance from "./Pages/UnderMaintenance";
import OtpVarification from "./Pages/OtpVarification";
import MyProfile from "./Pages/MyProfile";
import UserVerification from "./Pages/UserVerification";
import User from "./Pages/User";
import UserDetails from "./Pages/UserDetails";
import Support from "./Pages/Support";
import Notification from "./Pages/Notification";
import CreateNotification from "./Pages/CreateNotification";
import SupportChat from "./Pages/SupportChat";
import FaqManagement from "./Pages/FaqManagement";
import AddFaq from "./Pages/AddFaq";
import EditFaq from "./Pages/EditFaq";
import TrainerDetails from "./Pages/TrainerDetails";
import ProtectedRoutes from "./Components/Layout/ProtectedRoutes";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";
import CreatePost from "./Pages/CreatePost";
import Community from "./Pages/Community";
import EditPost from "./Pages/EditPost";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          ></Route>
          <Route element={<ProtectedRoutes />}>
            <Route
              path="/under-maintenance"
              element={<UnderMaintenance />}
            ></Route>
            <Route path="/notification" element={<Notification />}></Route>
            <Route path="/add-faqs" element={<AddFaq />}></Route>
            <Route path="/edit-faqs/:id" element={<EditFaq />}></Route>
            <Route
              path="/otp-varification"
              element={<OtpVarification />}
            ></Route>
            <Route path="/faqs" element={<FaqManagement />}></Route>
            <Route
              path="/create-notification"
              element={<CreateNotification />}
            ></Route>
            <Route path="/support-chat" element={<SupportChat />}></Route>
            <Route path="/support" element={<Support />}></Route>
            <Route path="/user" element={<User />}></Route>
            <Route path="/my-profile" element={<MyProfile />}></Route>
            <Route path="/user-details/:id" element={<UserDetails />}></Route>
            <Route path="/community" element={<Community/>}></Route>
            <Route path="/create-post" element={<CreatePost/>}></Route>
            <Route path="/edit-post/:id" element={<EditPost/>}></Route>

            <Route
              path="/trainer-details/:id"
              element={<TrainerDetails />}
            ></Route>
            <Route
              path="/user-verification"
              element={<UserVerification />}
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
