import { createSlice } from "@reduxjs/toolkit";
import { DowanloadSupportxlsv, Supportlist } from "../Actions/AdminActions";  // Ensure correct action import

export const Supportlistslice = createSlice({
    name: "Supportlistslice",
    initialState: {
        supportListAll: {},
        support: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(Supportlist.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(Supportlist.fulfilled, (state, action) => {
                state.loading = false;
                state.supportListAll = action.payload.data;
            })
            .addCase(Supportlist.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(DowanloadSupportxlsv.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(DowanloadSupportxlsv.fulfilled, (state, action) => {
                state.loading = false;
                state.support = action.payload.data;
            })
            .addCase(DowanloadSupportxlsv.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default Supportlistslice.reducer;
