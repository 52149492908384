import { createSlice } from "@reduxjs/toolkit";
import { FAQList } from "../Actions/AdminActions";

export const faqslice = createSlice({
    name: "faqslice",
    initialState: {
        faqalldata: "",
        loading: false,
        error: null,
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(FAQList.pending, (state) => {
                state.loading = true; 
                state.error = null;   
            })
            .addCase(FAQList.fulfilled, (state, action) => {
                state.loading = false; 
                state.faqalldata = action.payload.data; 
            })
            .addCase(FAQList.rejected, (state, action) => {
                state.loading = false; 
                state.error = action.error.message; 
            });
    },
});

export default faqslice.reducer;
