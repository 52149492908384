import { configureStore } from "@reduxjs/toolkit";
import { usermanageSlice } from "../Redux/Reducer/UsermgmtSlice";
import { faqslice } from "../Redux/Reducer/FaqSlice";
import { Supportlistslice } from "./Reducer/SupportSlice";
import { notificationSlice } from "./Reducer/NotificationSlice";
import { communitySlice } from "./Reducer/CommunitySlice";
import { instructionSlice } from "./Reducer/InstructionSlice";


export const Store = configureStore({
  reducer: {
    usermgt:usermanageSlice.reducer,
    faqmgmt:faqslice.reducer,
    suppmgmt:Supportlistslice.reducer,
    notimgmt:notificationSlice.reducer,
    community:communitySlice.reducer,
    instruction: instructionSlice.reducer
  },
});