import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Countrycode from "../Countrycode.json";
import * as yup from "yup";
import Logo from "../Assets/Images/Placeholder-(1)-1726559462042.png";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import {
  DeleteUser,
  DeleteUserAccount,
  getAdminprofile,
  getuserprofile,
  suspenduser,
} from "../Redux/Actions/AdminActions";

export default function MyProfile() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [Userdetails, setuserDetails] = useState("");
  const [ActivityDetails, setActivityDetails] = useState([]);
  const [showd, setshowd] = useState(false);
  const [show, setshow] = useState(false);
  const [flag, setflag] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getuserprofile({ id: id }))
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.data?.status === 200) {
          setuserDetails(response?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  const handleDeleteUSer = () => {
    dispatch(DeleteUserAccount({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
          navigate("/user");
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  const handleSuspend = async () => {
    const data = await dispatch(suspenduser({ id: id, status: 2 }));
    console.log(data);
    if (data?.payload?.data?.status === 200) {
      toast?.success(data?.payload?.data?.message);
      setshow(false);
      navigate("/user");
    }
  };

  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <div className="right-top-bar">
            <div className="inner-page-lable">
              <Link to="/user">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M3 8.00008L1.93934 9.06074L0.87868 8.00008L1.93934 6.93942L3 8.00008ZM23 6.50008C23.8284 6.50008 24.5 7.17165 24.5 8.00008C24.5 8.82851 23.8284 9.50008 23 9.50008V6.50008ZM8.60601 15.7274L1.93934 9.06074L4.06066 6.93942L10.7273 13.6061L8.60601 15.7274ZM1.93934 6.93942L8.60601 0.272755L10.7273 2.39408L4.06066 9.06074L1.93934 6.93942ZM3 6.50008L23 6.50008V9.50008L3 9.50008L3 6.50008Z"
                    fill="#071523"
                  />
                </svg>
                User Profile
              </Link>
            </div>
          </div>
        </div>
      </Container>

      <Container fluid>
        <div className="my-profile-page mt-4">
          <Form>
            <div className="profile-top-box">
              <div className="photo-upload">
                <div className="profile-picture">
                  <img
                    src={
                      Userdetails?.profile_pic
                        ? `${url}public/${Userdetails?.profile_pic}`
                        : Logo
                    }
                    //  alt="Profilesss"
                  />
                </div>
              </div>

              <div className="profile-disp mt-5">
                <div className="form-set input-profile">
                  <Form.Group
                    className="mb-3 input-field-width"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="type"
                      placeholder="First Name"
                      value={Userdetails?.name || "N/A"}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3 input-field-width"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={Userdetails?.email || "N/A"}
                    />
                  </Form.Group>
                </div>

                <div className="form-set input-profile mt-2">
                  <Form.Group
                    className="mb-3 input-field-width"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Bio</Form.Label>
                    <textarea
                      class="form-control"
                      placeholder="Leave a comment here"
                      value={Userdetails?.bio || "N/A"}
                    ></textarea>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 input-field-width"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Fitness Goals</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Contact"
                      value={Userdetails?.fitness_goals?.map((res) =>
                        res?.fitness_goals === 1
                          ? "weight loss"
                          : "Build muscle"
                      )}
                    />
                  </Form.Group>
                   
                </div>

                <div className="form-set input-profile mt-2">
                  <Form.Group
                    className="mb-3 input-field-width"
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Age"
                      value={Userdetails?.address || "N/A"}
                    />
                  </Form.Group>

                 
                </div>
              </div>
            </div>
          </Form>

          {/* <div className="profile-top-box mt-4">
            <div className="all-links">
              <div className="experience socal-media-links">
                <h2> SOCIAL MEDIA</h2>
                <div className="media-links">
                  <p>Facebook</p>
                  <span>{Userdetails?.fb_url || "N/A"}</span>
                </div>
                <div className="media-links">
                  <p>Instagram</p>
                  <span>{Userdetails?.instagram_url || "N/A"}</span>
                </div>
                <div className="media-links">
                  <p>Snapchat</p>
                  <span>{Userdetails?.snapchat_url || "N/A"}</span>
                </div>
              </div>
              
            </div>
          </div> */}

          <Row>
            <Col lg={12}>
              <div className="profile-top-box mt-4">
                <div className="heading-profile">
                  <h2>Document</h2>
                  {/* <div className="upld-photo-lable ">
                    <div className="iupld-photo-item">
                      <p>Identity proof</p>
                      {Userdetails?.govt_id ? (
                        Userdetails.govt_id.endsWith(".pdf") ? (
                          <a
                            href={`${url}public/${Userdetails.govt_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Document
                          </a>
                        ) : (
                          <img
                            src={`${url}public/${Userdetails.govt_id}`}
                            alt="Profile"
                          />
                        )
                      ) : (
                        <p>No document found</p>
                      )}
                    </div>
                  </div> */}
                  <div className="upld-photo-label">
  <div className="iupld-photo-item">
    {Userdetails?.govt_id ? (
      Userdetails.govt_id.endsWith(".pdf") ? (
        <div className="cv-upld-view">
          <a
            className="profile-add-img"
            href={`${url}public/${Userdetails.govt_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../Assets/Images/pdf_icon.png")} 
              alt="Open Document"
              style={{ cursor: "pointer" }}
            />
            <p>View Document</p>
          </a>
        </div>
      ) : (
        <div className="cv-upld-view">
          <img
            className="profile-add-img"
            src={`${url}public/${Userdetails.govt_id}`}
            alt="Profile"
            style={{ cursor: "pointer" }}
            onClick={() => window.open(`${url}public/${Userdetails.govt_id}`, "_blank")}
          />
          <p>View Image</p>
        </div>
      )
    ) : (
      <p>No document found</p>
    )}
  </div>
</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Form>
                <div className="profile-top-box mt-4">
                  <div className="heading-profile">
                    <h2>Activity</h2>
                  </div>
                  <div className="activity-list-user">
                    {ActivityDetails?.map((details) => {
                      return (
                        <ul>
                          <li>
                            <h6>{details?.title}</h6>
                            <p>
                              {ActivityDetails && ActivityDetails.length > 0 ? (
                                <ul>
                                  {ActivityDetails.map((details, index) => (
                                    <li key={index}>
                                      <h6>{details?.title}</h6>
                                      <p>
                                        {details?.createdAt
                                          ? new Date(details?.createdAt)
                                              .toISOString()
                                              .slice(0, 10)
                                              .replace(/-/g, "/")
                                          : "N/A"}
                                      </p>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p>No activities happened yet</p>
                              )}
                            </p>
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                </div>
              </Form>
            </Col>

            <Col lg={6}>
              <Row>
                <Col lg={12}>
                  <div className="profile-top-box mt-4">
                    <div className="heading-profile">
                      <h2>Delete Account</h2>
                      <p>
                        Once you delete your account, you can not retrieve the
                        account. Please be certain.
                      </p>
                      <div className="btn-up mt-2">
                        <button type="button" onClick={() => setshowd(true)}>
                          Delete Account
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="profile-top-box mt-4">
                    <div className="heading-profile">
                      <h2>Suspend Account</h2>
                      <p>
                        You will not be able to receive messages, notifications
                        for up to 24hours.
                      </p>
                      <div className="btn-up mt-2">
                        <button type="button" onClick={() => setshow(true)}>
                          Suspend Account
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to Delete this Account?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeleteUSer}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to Suspend this Account?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleSuspend}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
