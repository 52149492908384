import { all } from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { usermanagement } from "../Redux/Actions/AdminActions";

function Commondashboard() {
  const { alldata, loading, error, parms } = useSelector(
    (state) => state.usermgt
  );

  console.log(alldata);

  const dispatch = useDispatch();

  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [flag, setflag] = useState(true);

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: searchValue,
    };
    dispatch(usermanagement(searchData));
  }, [page, limit, searchValue, flag, dispatch]);

  return (
    <Container fluid>
      <div className="dashboard-items">
        <Row>
          <Col xxl={12} xl={12} lg={12}>
            <div className="dashbox-inner-wrap">
              <Row>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes">
                    <div className="session">
                      <div className="left-session">
                        <p>Total Users</p>
                        <h2> {alldata?.count} </h2>
                      </div>
                      <div className="user-icon-se">
                        <img
                          src={
                            require("../Assets/Images/session-user.svg").default
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes">
                    <div className="session">
                      <div className="left-session">
                        <p>Total Trainers</p>
                        <h2> {alldata?.total_trainers || "0"}</h2>
                      </div>
                      <div className="user-icon-se">
                        <img
                          src={
                            require("../Assets/Images/padi-user.svg").default
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes">
                    <div className="session">
                      <div className="left-session">
                        <p>Active Users</p>
                        <h2>0</h2>
                      </div>
                      <div className="user-icon-se">
                        <img
                          src={
                            require("../Assets/Images/active-user.svg").default
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xxl={3} xl={3} lg={3}>
                  <div className="dash-inner-boxes">
                    <div className="session">
                      <div className="left-session">
                        <p>In-Active Users</p>
                        <h2>0</h2>
                      </div>
                      <div className="user-icon-se">
                        <img
                          src={
                            require("../Assets/Images/pending-user.svg").default
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default Commondashboard;
