import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";

// ==================== change Password ======================

export const chnagePassword = createAsyncThunk(
  "chnagePassword",
  async (details) => {
    const data = await AdminAPI.patch(`/change-password`, details);
    console.log(data);
    return data;
  }
);

// ===== Get Admin Profile ==========

export const getAdminprofile = createAsyncThunk("getAdminprofile", async () => {
  const { data } = await AdminAPI.get(`/profile`);
  return data;
});

// ====== Edit admin profile ==========

export const Editadminprofile = createAsyncThunk(
  "Editadminprofile",
  async (details) => {
    const data = await AdminAPI.put(`/profile`, details);
    console.log(data);
    return data;
  }
);

// ========= get user list ============

export const usermanagement = createAsyncThunk(
  "usermanagement",
  async (details) => {
    let url = `/users?pagination=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// ======== Delete User account api =============

export const DeleteUser = createAsyncThunk("DeleteUser", async (details) => {
  const data = await AdminAPI.delete(`/user/${details?.id}`);
  return data;
});

// =======  Dowanload Users List ============

export const DowanloadUserxlsv = createAsyncThunk(
  "DowanloadUserxlsv",
  async () => {
    const data = await AdminAPI.get(`/users`);
    console.log(data);
    return data;
  }
);

// ===== Forget Password =======

export const forgetPass = createAsyncThunk("forgetPass", async (details) => {
  const data = await AdminAPI.post(`/forgot-password`, details);
  return data;
});

// ======= Reset Password ===========

export const ResetPass = createAsyncThunk("ResetPass", async (details) => {
  const data = await AdminAPI.post(`/set-password`, details);
  return data;
});

// ===== Get user Profile =====

export const getuserprofile = createAsyncThunk(
  "getuserprofile",
  async (details) => {
    const data = await AdminAPI.get(`/user/${details?.id}`);
    console.log(data);
    return data;
  }
);

// ================ get user activity ===================

// export const  getActivity = createAsyncThunk(
//   "getActivity",
//   async(details)=> {
//      const {data} = await AdminAPI.get(`/getActivities?user_id=${details?.id}`)
//      return data;
//   }
// );

// ======== suspend User account api =============

export const suspenduser = createAsyncThunk("suspenduser", async (details) => {
  const data = await AdminAPI.patch(`/user/${details?.id}`, details);
  return data;
});

// ======== Delete User account api =============

export const DeleteUserAccount = createAsyncThunk(
  "DeleteUserAccount",
  async (details) => {
    const data = await AdminAPI.delete(`/user/${details?.id}`, details);
    return data;
  }
);

// ======== magage User account api =============

export const maangeuseraction = createAsyncThunk(
  "maangeuseraction",
  async (details) => {
    const data = await AdminAPI.patch(`/user/${details?.id}`, details);
    return data;
  }
);

// ============== Add FAQ ===============

export const addfaq = createAsyncThunk("AddFaq", async (details) => {
  const data = await AdminAPI.post(`/faq`, details);
  return data;
});

// ==================  List FAQ =================

export const FAQList = createAsyncThunk("FAQList", async (details) => {
  let url = `/faq?pagination=${details?.page}&limit=${details?.limit}`;

  if (details.search) {
    url += `&search=${details.search}`;
  }

  const data = await AdminAPI.get(url);
  console.log(data);
  return data;
});

// ============== dowanload FAQ ===============

export const dowanloadfaq = createAsyncThunk("dowanloadfaq", async () => {
  const data = await AdminAPI.get(`/faq`);
  return data;
});

// ============ Edit faq ================

export const editFaq = createAsyncThunk("EditFaq", async (details) => {
  const data = await AdminAPI.put(`/faq/${details?.id}`, details);
  return data;
});

// ==========  delete faq =================

export const deletefaq = createAsyncThunk("deletefaq", async (details) => {
  const data = await AdminAPI.delete(`/faq/${details?.id}`, details);
  return data;
});

// ==========  get faq details =================

export const getfaqdetails = createAsyncThunk(
  "gatfaqdetails",
  async (details) => {
    const data = await AdminAPI.get(`/faq/${details?.faqid}`);
    return data;
  }
);

// ==================  List Support tab =================

export const Supportlist = createAsyncThunk("Supportlist", async (details) => {
  let url = `/support?pagination=${details?.page}&limit=${details?.limit}&search=${details?.search}`;

  if (details.search) {
    url += `&search=${details.search}`;
  }

  const data = await AdminAPI.get(url);
  console.log(data);
  return data;
});
export const DowanloadSupportxlsv = createAsyncThunk(
  "DowanloadSupportxlsv",
  async () => {
    const data = await AdminAPI.get(`/support`);
    console.log(data);
    return data;
  }
);

//  =======  Notifiaction Listing  =======

export const getnotificationlist = createAsyncThunk(
  "getnotificationlist",
  async (details) => {
    let url = `/notification?pagination=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// =========  Dowanload Notifications File =============

export const DowanloadNotificationFile = createAsyncThunk(
  "DowanloadNotificationFile",
  async (details) => {
    const data = await AdminAPI.get(`/notification`, details);
    console.log(data);
    return data;
  }
);

// =========  Add Notification =============

export const Addnotification = createAsyncThunk(
  "Addnotification",
  async (details) => {
    const data = await AdminAPI.post(`/notification`, details);
    console.log(data);
    return data;
  }
);

// =========  Delete Notification =============

export const DeleteNotification = createAsyncThunk(
  "DeleteNotification",
  async (details) => {
    const data = await AdminAPI.delete(`/notification/${details?.id}`);
    console.log(data);
    return data;
  }
);

//  =======  Community Listing  =======

export const Communitylisting = createAsyncThunk(
  "Communitylisting",
  async (details) => {
    let url = `/community-posts?pagination=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

//  Create Post

export const createpost = createAsyncThunk("createpost", async (details) => {
  const data = await AdminAPI.post(`/community-post`, details);
  console.log(data);
  return data;
});

// dowanload community list

export const DowanloadCommunitylist = createAsyncThunk(
  "DowanloadCommunitylist",
  async () => {
    const data = await AdminAPI.get(`/community-posts`);
    console.log(data);
    return data;
  }
);

// delete community

export const DeleteCommunity = createAsyncThunk(
  "DeleteCommunity",
  async (details) => {
    const data = await AdminAPI.delete(`/community-post/${details?.id}`);
    console.log(data);
    return data;
  }
);

// get post detail by id

export const getPostdetail = createAsyncThunk(
  "getPostdetail",
  async (details) => {
    const data = await AdminAPI.get(`/community-post/${details?.id}`);
    console.log(data);
    return data;
  }
);

// Edit post

export const UpdatedPost = createAsyncThunk(
  "UpdatedPost",
  async ({ id, formData }) => {
    const data = await AdminAPI.put(`/community-post/${id}`, formData);
    console.log(data);
    return data;
  }
);

//  Add Instruction

export const AddInstruction = createAsyncThunk(
  "AddInstruction",
  async (details) => {
    const data = await AdminAPI.post(`/instruction`, details);
    console.log(data);
    return data;
  }
);

 // get insteructions

export const getInstruction = createAsyncThunk(
  "getInstruction",
  async (details) => {
    const data = await AdminAPI.get(`/instruction`);
    console.log(data);
    return data;
  }
);


// Edit Instruction

export const editInstruction = createAsyncThunk(
  "editInstruction",
  async (details) => {
    const data = await AdminAPI.put(`/instruction`,details);
    console.log(data);
    return data;
  }
);
