import { createSlice } from "@reduxjs/toolkit";
import { getInstruction } from "../Actions/AdminActions";

export const instructionSlice = createSlice({
  name: "instructionSlice",
  initialState: {
    instructionData: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInstruction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInstruction.fulfilled, (state, action) => {
        state.loading = false;
        state.instructionData = action?.payload?.data;
      })
      .addCase(getInstruction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default instructionSlice.reducer;
