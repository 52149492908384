import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import * as XLSX from "xlsx";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  DowanloadSupportxlsv,
  Supportlist,
} from "../Redux/Actions/AdminActions";
import Commondashboard from "../Common/Commondashboard";
import PaginationComponet from "../Components/Pagination";
import moment from "moment";
import SearchBar from "../Common/SearchBar";
import Commonheader from "../Common/Commonheader";

export default function Support() {
  const supportdata = useSelector((state) => state?.suppmgmt?.supportListAll);
  const [searchValue, setSearchValue] = useState("");
  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [faqid, setfaqId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: searchValue,
    };
    dispatch(Supportlist(searchData));
  }, [page, limit, flag, searchValue, dispatch]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      Supportlist({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadSupportxlsv());
      debugger;
      console.log(response);

      const allData = response?.payload?.data?.data;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  return (
    <Layout>
      <Container fluid>
        <Commonheader/>
      </Container>
      <Commondashboard />

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <SearchBar setSearchValue={setSearchValue} />
                    </Form.Group>
                  </Form>
                </div>
                <div className="export-box" onClick={fetchDataAndExportToExcel}>
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">Ticket Id</div>
                    </th>
                    <th>Date</th>
                    <th>Sender Name</th>
                    <th>Email </th>
                    <th>Description </th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {supportdata?.data &&
                  Array.isArray(supportdata?.data) &&
                  supportdata?.data?.length > 0 ? (
                    <>
                      {supportdata?.data.map((ele, i) => {
                        
                        return (
                          <tr key={i}>
                            <td>
                              <div className="first-user">
                                <div className="user-profile">
                                  {/* <img
                                    src={
                                      require("../Assets/Images/user.svg")
                                        .default
                                    }
                                  /> */}
                                  <div className="user-id">
                                    <p>Jordan Stevenson</p>
                                    {/* <Link to="/support-chat">
                                      jordan.stevenson
                                    </Link> */}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="user-role">
                                <p>
                                  {moment(ele?.createdAt).format("DD/MM/YYYY")}
                                </p>
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div className="cont-num">James </div>
                            </td>
                            <td>
                              <div className="debit">abc@domain.com</div>
                            </td>
                            <td>
                              <div className="debit">{ele?.message}</div>
                            </td>
                            <td>
                              {ele.state == 0 ? (
                                <div className="pending">
                                  <p>Close</p>
                                </div>
                              ) : (
                                <div className="pending">
                                  <p>Open</p>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <div className="data-not-found">
                      <p>No Data Found</p>
                    </div>
                  )}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {currentPage * limit + 1} -{" "}
                    {Math.min((currentPage + 1) * limit, supportdata?.count)} of{" "}
                    {supportdata?.count} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={currentPage}
                    totalCount={supportdata?.count}
                    limit={Math.ceil(supportdata?.count / limit)}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>
    </Layout>
  );
}
