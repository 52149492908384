import { createSlice } from "@reduxjs/toolkit";
import { getnotificationlist } from "../Actions/AdminActions";


export const notificationSlice = createSlice({
    name: "notificationSlice",
    initialState: {
        AllNoti: [],
        loading: false,
        error: null,
    },
    reducers: {
        // You can add other reducers if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(getnotificationlist.pending, (state) => {
                state.loading = true; 
                state.error = null;   
            })
            .addCase(getnotificationlist.fulfilled, (state, action) => {
                // debugger
                state.loading = false; 
                state.AllNoti = action?.payload?.data;
            })
            .addCase(getnotificationlist.rejected, (state, action) => {
                state.loading = false; 
                state.error = action.error.message; 
            });
    },
});

export default notificationSlice.reducer;
