import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Commondashboard from "../Common/Commondashboard";
import { Button, Modal } from "react-bootstrap";
import SearchBar from "../Common/SearchBar";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  UpdatedPost,
  createpost,
  getPostdetail,
} from "../Redux/Actions/AdminActions";
import { toast } from "react-toastify";
import Commonheader from "../Common/Commonheader";

const validationSchema = Yup.object({
  description: Yup.string().required("Description is required"),
  media: Yup.mixed().required("Image is required"),
});

export default function EditPost() {
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [imagePreview, setImagePreview] = useState(null);
  const [Postdetails, setpostdetails] = useState({});
  console.log(Postdetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const initialValues = {
    description: "",
    media: null,
  };

  useEffect(() => {
    dispatch(getPostdetail({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          setpostdetails(res?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch, id]);

  const handleSubmit = (values) => {
    console.log(values, "Asdasdasdas");
    const { media, description } = values;
    const formData = new FormData();

    if (media) {
      formData.append("media", media);
    }
    formData.append("description", description);
    dispatch(UpdatedPost({ id, formData }))
      .then((res) => {
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          navigate("/community");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleImageChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      // Set the image file in Formik
      setFieldValue("media", file);

      // Create a preview URL for the image using URL.createObjectURL
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  return (
    <Layout>
      <Container fluid>
        <Commonheader />
      </Container>

      <Container fluid>
        <div className="filters">
          <Formik
            initialValues={Postdetails || initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleSubmit, setFieldValue }) => (
              <FormikForm>
                <div className="inner-filter-field pb-5">
                  <div className="user-search border-bottom-line">
                    <div className="create-post comn-back-page">
                    <Link to="/community" className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="16"
                  viewBox="0 0 25 16"
                  fill="none"
                >
                  <path
                    d="M3 8.00008L1.93934 9.06074L0.87868 8.00008L1.93934 6.93942L3 8.00008ZM23 6.50008C23.8284 6.50008 24.5 7.17165 24.5 8.00008C24.5 8.82851 23.8284 9.50008 23 9.50008V6.50008ZM8.60601 15.7274L1.93934 9.06074L4.06066 6.93942L10.7273 13.6061L8.60601 15.7274ZM1.93934 6.93942L8.60601 0.272755L10.7273 2.39408L4.06066 9.06074L1.93934 6.93942ZM3 6.50008L23 6.50008V9.50008L3 9.50008L3 6.50008Z"
                    fill="#071523"
                  />
                </svg>
                Edit Post
              </Link>
                    </div>
                  </div>
                  <div className="discription-box-section">
                    <div className="upload-and discp">
                      <div className="type-disp">
                        <div className="form-set ">
                          <Form.Group className=" " controlId="formBasicEmail">
                            <Form.Label>Description</Form.Label>
                            <textarea
                              class="form-control"
                              placeholder="Type here"
                              rows={5}
                              name="description"
                              value={values?.description}
                              onChange={handleChange}
                            ></textarea>
                            <ErrorMessage
                              name="description"
                              className="error-message"
                              component="div"
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="upload-images">
                        <div className="text-label">
                          <p>Upload Photo</p>
                        </div>
                        <div className="uploade-btn-image">
                          {imagePreview ? (
                            <img
                              src={imagePreview}
                              alt="Preview"
                              className="image-preview"
                            />
                          ) : values?.media ? (
                            <img
                              src={`${url}public/${values?.media}`}
                              alt="Profile"
                              className="image-preview"
                            />
                          ) : null}

                          <input
                            id="image"
                            name="media"
                            type="file"
                            accept="image/*"
                            onChange={(event) =>
                              handleImageChange(event, setFieldValue)
                            }
                          />

                          <div className="support-format">
                            <h3>Upload image</h3>
                            <span>Supported format: .png, .jpg, .jpeg</span>
                          </div>
                        </div>
                        <ErrorMessage
                          name="media"
                          className="error-message"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="Create-btn-op">
                      <button type="submit">Update</button>
                    </div>
                  </div>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
