import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import {
  DeleteUser,
  DowanloadUserxlsv,
  getAdminprofile,
  maangeuseraction,
  suspenduser,
  usermanagement,
} from "../Redux/Actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../Assets/Images/Placeholder-(1)-1726559462042.png";
import UserIcon from "../Assets/Images/table-user.svg";
import TrainerIcon from "../Assets/Images/crown.svg";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import PaginationComponet from "../Components/Pagination";
import { OverlayTrigger, Popover } from "react-bootstrap";
import SearchBar from "../Common/SearchBar";
import { CommonLogout } from "../Common/CommonLogout";
import Commondashboard from "../Common/Commondashboard";
import Commonheader from "../Common/Commonheader";

export default function User() {
  const { alldata, loading, error, param } = useSelector(
    (state) => state.usermgt
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(10);

  const [flag, setflag] = useState(true);
  const [userid, setUserid] = useState("");
  const [curruntImage, setCurruntImage] = useState("");
  const [admindetails, setAdminDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [activePopoverId, setActivePopoverId] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      usermanagement({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    dispatch(getAdminprofile())
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.status === 200) {
          setAdminDetails(response?.payload?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: searchValue,
    };
    dispatch(usermanagement(searchData));
  }, [page, limit, searchValue, flag, dispatch]);

  const handleDeleteUSer = () => {
    dispatch(DeleteUser({ id: userid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadUserxlsv());
      console.log(response);

      const allData = response?.payload?.data?.data;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const ActiveOrDeactivate = (userid, status) => {
    dispatch(maangeuseraction({ id: userid, status: status }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setActivePopoverId(null);
          setflag(!flag);
        }
      })
      .catch((error) => {
        console.log(error, "Error occurred");
      });
  };

  const handleTogglePopover = (id) => {
    setActivePopoverId((prevId) => (prevId === id ? null : id));
  };

  const popover = (id) => {
    return (
      <Popover id="popover-basic" style={{ cursor: "pointer" }}>
        <Popover.Body>
          <p onClick={() => ActiveOrDeactivate(id, 1)}>Active</p>
          <p onClick={() => ActiveOrDeactivate(id, 2)}>In-Active</p>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <Layout>
   
      <>
        <Container fluid>
         <Commonheader/>
        </Container>
          <Commondashboard/>
        <Container fluid>
          <div className="filters">
            <div className="inner-filter-field">
              <div className="user-search">
                <div className="drop-down">
                  <div className="select-box">
                    <Form.Select
                      aria-label="Default select example"
                      name="limit"
                      onChange={(e) => setlimit(e.target.value)}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                    </Form.Select>
                  </div>
                </div>
                <div className="search-tab">
                  <div className="input-search">
                    <Form>
                      <Form.Group controlId="formBasicPassword">
                        <SearchBar setSearchValue={setSearchValue} />
                      </Form.Group>
                    </Form>
                  </div>
                  <div
                    className="export-box"
                    onClick={fetchDataAndExportToExcel}
                  >
                    <p>
                      <span>
                        <img
                          src={require("../Assets/Images/upload.svg").default}
                        />
                      </span>
                      Export
                    </p>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <Table size="sm" className="table-cmn">
                  <thead>
                    <tr>
                      <th>
                        {/* <div className="user-checkbox"> */}
                        {/* <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div> */}
                        User
                        {/* </div> */}
                      </th>
                      <th>Role</th>
                      <th>Contact</th>
                      <th>Age </th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alldata?.data?.map((res, index) => {
                      return (
                        <tr>
                          <td>
                            <div className="first-user">
                              {/* <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div> */}
                              <div className="user-profile">
                                <img
                                  src={
                                    res?.profile_pic
                                      ? `${url}public/${res?.profile_pic}`
                                      : Logo
                                  }
                                  style={{ borderRadius: 50 }}
                                />
                                <div className="user-id">
                                  <p>{res?.name || "N/A"}</p>
                                  <span>{res?.email || "N/A"}</span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="user-role">
                              <p>
                                <span>
                                  <img
                                    src={
                                      res?.role === 1 ? UserIcon : TrainerIcon
                                    }
                                  />
                                </span>
                                {res?.role === 1 ? "User" : "Trainer"}
                              </p>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className="cont-num">
                              {" "}
                              {res?.phone_no
                                ? res.country_code
                                  ? `${res.country_code} ${res.phone_no}`
                                  : `${res.phone_no}`
                                : "N/A"}{" "}
                            </div>
                          </td>
                          <td>
                            <div className="debit">{res?.age || "N/A"}</div>
                          </td>
                          <td>
                            <div
                              className={`${
                                res?.account_status === 1 ? "active" : "pending"
                              }`}
                            >
                              <p>
                                {res?.account_status === 1
                                  ? "Active"
                                  : "Inactive"}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div className="action">
                              <img
                                src={
                                  require("../Assets/Images/delete-icon.svg")
                                    .default
                                }
                                onClick={() => {
                                  setshowd(true);
                                  setUserid(res?._id);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <Link
                                to={
                                  res?.role == 2
                                    ? `/trainer-details/${res?._id}`
                                    : `/user-details/${res?._id}`
                                }
                              >
                                <img
                                  src={
                                    require("../Assets/Images/view-icon.svg")
                                      .default
                                  }
                                />
                              </Link>

                              <OverlayTrigger
                                trigger="click"
                                show={activePopoverId === res._id}
                                placement="bottom"
                                overlay={popover(res?._id)}
                                onToggle={() => handleTogglePopover(res._id)}
                                rootClose
                              >
                                <img
                                  src={
                                    require("../Assets/Images/options.svg")
                                      .default
                                  }
                                  alt="Options"
                                  style={{ cursor: "pointer" }}
                                />
                              </OverlayTrigger>

                              {/* <img
                              src={
                                require("../Assets/Images/options.svg").default
                              }
                            /> */}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="pagination-section">
                  <div className="showing-user">
                    <p>
                      {" "}
                      Showing {currentPage * limit + 1} -{" "}
                      {Math.min((currentPage + 1) * limit, alldata?.count)} of{" "}
                      {alldata?.count} results
                    </p>
                  </div>
                  <div className="pagination-block">
                    <PaginationComponet
                      currentPage={currentPage}
                      totalCount={alldata?.count}
                      limit={Math.ceil(alldata?.count / limit)}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="made-with">
            <div className="pixinvent">
              <p>
                {" "}
                © 2024, Made with ❤️ by <span>Codobux</span>
              </p>
            </div>
          </div>
        </Container>
      </>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to Remove this Account?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeleteUSer}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
